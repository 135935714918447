<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  progress: number;
}>();

const radius = 25; // Zmiana promienia na większy, aby pasował do rysunku
const circumference = 2 * Math.PI * radius;

const normalizedProgress = computed(() =>
  Math.min(Math.max(props.progress, 0), 100),
);
const strokeDashoffset = computed(
  () => circumference - (normalizedProgress.value / 100) * circumference,
);
</script>

<template>
  <svg viewBox="0 0 60 60" width="60" height="60">
    <circle
      cx="30"
      cy="30"
      r="25"
      fill="transparent"
      stroke="lightgrey"
      stroke-width="7"
    />
    <circle
      cx="30"
      cy="30"
      r="25"
      fill="transparent"
      stroke="currentColor"
      stroke-width="7"
      :stroke-dasharray="circumference"
      :stroke-dashoffset="strokeDashoffset"
      transform="rotate(-90 30 30)"
      stroke-linecap="round"
    />
  </svg>
</template>
